import React from "react";
import { graphql } from "gatsby"
import { withPreview } from 'gatsby-source-prismic'
import styles from './Textpage.module.scss';

import SEO from 'components/Global/SEO';

const TextPageTemplate = ({data}) => {

  const page = data.page.data;

  return (
    <div>
      <SEO title={page.titel.text} description={page.titel.text} />
      <div className="container mb-5">
        <div className="row">
          <div className="col-md-6">
            <h1 className="text-primary text-break">{page.titel.text}<span className="text-secondary">.</span></h1>
            {page.intro.html && (
              <div dangerouslySetInnerHTML={{ __html: page.intro.html }}></div>
            )}
          </div>
        </div>
        {page.body.html && (
          <div className="row">
            <div className="col-md-8">
              <div 
                dangerouslySetInnerHTML={{ __html: page.body.html }}
                className={styles.text}
              ></div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export const query = graphql`
  query($id: String!) {
    page: prismicTekstpagina(id: {eq: $id}) {
      data {
        titel {
          text
        }
        intro {
          html
        }
        body {
          html
        }
      }
    }
  }
`

export default withPreview(TextPageTemplate);